const BaseFilter = require('./base-filter');
const getBlocksForRange = require('./getBlocksForRange');
const {
  incrementHexInt
} = require('./hexUtils');
class TxFilter extends BaseFilter {
  constructor({
    provider
  }) {
    super();
    this.type = 'tx';
    this.provider = provider;
  }
  async update({
    oldBlock
  }) {
    const toBlock = oldBlock;
    const fromBlock = incrementHexInt(oldBlock);
    const blocks = await getBlocksForRange({
      provider: this.provider,
      fromBlock,
      toBlock
    });
    const blockTxHashes = [];
    for (const block of blocks) {
      blockTxHashes.push(...block.transactions);
    }
    // add to results
    this.addResults(blockTxHashes);
  }
}
module.exports = TxFilter;